<template>
  <main class="register-reseller w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="register-reseller__numbers">
      <span class="register-reseller__number register-reseller__number_active">1</span>
      <span class="register-reseller__number">2</span>
      <span class="register-reseller__number">3</span>
      <span class="register-reseller__number">4</span>
    </section>
    <h1 class="title title__h1 register-reseller__title">{{ $t("REGISTER_RESELLER.MARKETER_REGISTRATION_BUSINESS") }}</h1>
    <p class="text register-reseller__text">{{ $t("REGISTER_RESELLER.WELCOME_ENTER_TXT") }}</p>
    <section class="reseller-info register-reseller__reseller-info">
      <form class="reseller-info__container">
        <div class="reseller-info__wrapper">
          <div class="reseller-info__company reseller-info__case">
            <label class="label reseller-info__label">* {{ $t("REGISTER_RESELLER.BUSINESS_NAME") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__dealer reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.AUTHORIZED_DEALER_HP") }} *</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__type reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.TYPE_OF_BUSINESS") }}</label>
            <div class="reseller-info__dropdown">
              <select class="reseller-info__dropdown-select">
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                <option value="4">Four</option>
              </select>
              <button class="button reseller-info__dropdown-button"></button>
              <div class="reseller-info__dropdown-list">
                <a class="link reseller-info__dropdown-option" href="#" data-value="1">One</a>
                <a class="link reseller-info__dropdown-option" href="#" data-value="2">Two</a>
                <a class="link reseller-info__dropdown-option" href="#" data-value="3">Three</a>
                <a class="link reseller-info__dropdown-option" href="#" data-value="4">Four</a>
              </div>
            </div>
          </div>

          <div class="reseller-info__phone-reseller reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.BUSINESS_PHONE") }}</label>
            <input class="input" type="tel" />
          </div>

          <div class="reseller-info__phone-contact reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.MOBILE_PHONE_CONTACT") }}</label>
            <input class="input" type="tel" />
          </div>

          <div class="reseller-info__article reseller-info__case">
            <input class="reseller-info__checkbox" type="checkbox" id="article" />
            <label class="label reseller-info__checkbox-label" for="article">{{ $t('REGISTER_RESELLER.ARTICLE', { num: '20' }) }}</label>
          </div>
        </div>

        <div class="reseller-info__wrapper">
          <div class="reseller-info__city reseller-info__case">
            <label class="label reseller-info__label">{{ $t("COMMON.CITY") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{ $t("COMMON.ADDRESS") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__post reseller-info__case">
            <label class="label reseller-info__label">{{ $t("COMMON.POSTAL_CODE") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__work reseller-info__case">
            <label class="label reseller-info__label">{{ $t("COMMON.ACTIVITY_TIME") }}</label>

            <div class="reseller-info__work-wrapper">
              <div class="reseller-info__work-days">
                <div class="reseller-info__sunday">
                  <label class="label reseller-info__sunday-label">{{ $t("REGISTER_RESELLER.SUNDAYS_THURSDAYS") }}</label>
                  <div class="reseller-info__sunday-wrapper">
                    <input class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input class="input reseller-info__input" type="text" />
                  </div>
                </div>

                <div class="reseller-info__friday">
                  <label class="label reseller-info__friday-label">{{ $t("REGISTER_RESELLER.FRIDAY_HOLIDAY_EVES") }}</label>
                  <div class="reseller-info__friday-wrapper">
                    <input class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input class="input reseller-info__input" type="text" />
                  </div>
                </div>

                <div class="reseller-info__saturday">
                  <label class="label reseller-info__saturday-label" for>{{ $t("REGISTER_RESELLER.SATURDAYS_HOLIDAYS") }}</label>
                  <div class="reseller-info__saturday-wrapper">
                    <input class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input class="input reseller-info__input" type="text" />
                  </div>
                </div>
              </div>

              <div class="reseller-info__everyday">
                <input class="reseller-info__checkbox" type="checkbox" id="everyday" />
                <label class="label reseller-info__checkbox-label" for="everyday">24X7</label>
              </div>
            </div>
          </div>
        </div>

        <div class="reseller-info__wrapper">
          <div class="reseller-info__email reseller-info__case">
            <label class="label reseller-info__label">{{ $t("COMMON.E_MAIL") }}</label>
            <input class="input" type="email" />
          </div>

          <div class="reseller-info__email-2 reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.EMAIL_ACCOUNTING") }}</label>
            <input class="input" type="email" />
          </div>

          <div class="reseller-info__profile reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.VENDOR_PROFILE") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__percent reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.PROFILE_PERCENTAGE_PROFILE") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__commission reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.FEES_PROFILE") }}</label>
            <input class="input" type="text" />
          </div>

          <div class="reseller-info__percent-tourism reseller-info__case">
            <label class="label reseller-info__label">{{ $t("REGISTER_RESELLER.TOURISM_PERCENTAGE_PROFILE") }}</label>
            <input class="input" type="text" />
          </div>
        </div>
      </form>

      <a href="register-reseller-1.html" class="link reseller-info__link">{{ $t("COMMON.CONTINUE") }}</a>
    </section>
  </main>
</template>

<script>
export default {
  name: "RegisterReseller1",
};
</script>
<style lang="scss">
.ltr-type {
  .reseller-owner__plus {
    margin-left: unset;
    margin-right: 16px;
  }
  .reseller-docs__text {
    margin-right: unset;
    margin-left: 20px;
  }
  .reseller-info__checkbox-label::after {
    right: unset;
    left: -27px;
  }
  .reseller-info__checkbox-label::before {
    right: unset;
    left: -30px;
  }
  .reseller-info__article {
    margin-left: 32px;
    margin-right: unset;
  }
}
</style>